.mapboxgl-popup {
  max-width: 300px !important;
}
.mapboxgl-popup-content {
  border-radius: 24px !important;
  padding: 0 !important;
  border: 4px solid $color-black;

  .mapboxgl-popup-close-button {
    padding-right: 0.5rem;
    color: $color-light;
  }
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $color-black !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: $color-black !important;
}

.popup {
  &__header {
    background-color: $color-workshop;
    color: white;
    padding: 1rem;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0;
    }
    img {
      object-fit: scale-down;
    }
  }
  &__tooltip {
    font-size: var(--arc-font-size-x-small);
  }
  &__photo {
    width: 100%;
    border-top: 4px solid #000;
    border-bottom: 4px solid #000;
  }
  &__content {
    padding: 1rem;

    p {
      margin: 0;

      .copyright {
        font-size: var(--arc-font-size-xx-small);
      }
    }
  }
}
