/* ARC BASE STYLES */
@import "~@arc-web/components/dist/themes/index.css";
@import "~@arc-web/components/dist/themes/light.css";
@import "~@arc-web/components/dist/themes/dark.css";

/* HELIPOPPER STYLES */
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/animations/scale.css";

/* ABSTRACT STYLES */
@import "./abstracts/variables";

/* BASE STYLES */
@import "./base/typography";

/* COMPONENT STYLES */
@import "./components/layer-list";
@import "./components/popup";

/* LAYOUT STYLES */
@import "./layout/body";

/* MATERIAL THEME */
@import "./themes/material";

mat-checkbox {
  width: 100%;
  .mdc-form-field {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    label {
      line-height: 1.4;
    }
    &--align-end {
      label {
        margin-left: 0;
      }
    }
  }
}
