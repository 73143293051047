@import "https://fonts.googleapis.com/css?family=Noto+Sans:300,400,600,700";
:root,
* {
  font-family: "Noto Sans", sans-serif;
}

h1 {
  font-size: var(--arc-font-size-medium);
}

h2 {
  font-size: 1.3125rem;
}

h3 {
  font-size: var(--arc-font-size-small);
}

p {
  font-size: var(--arc-font-size-x-small);
}
