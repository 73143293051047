.layer-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  margin: 1rem;
  max-width: 17.5rem;
  max-height: 95%;
  overflow-y: scroll;

  h2 {
    margin: 0;
  }

  &__category {
    .category {
      &__button {
        position: absolute;
        bottom: 0.875rem;

        button {
          border-radius: 2rem;
          background-color: $color-button;
          color: $color-light;
          font-size: var(--arc-font-size-x-small);
          flex-direction: row;
          box-shadow: 0px 1.41372px 2.12058px #000000;
          gap: 0.875rem;
          padding: 0 1.5rem;
        }
      }

      &__description {
        background-color: $color-background;
        padding: var(--arc-spacing-normal);
      }

      &__expansion {
        background-color: $color-background;
        box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2) !important;
      }

      &__header {
        background-color: $color-secondary;
        padding: var(--arc-spacing-normal);
        padding-top: 1.5rem;
        padding-bottom: 4.5rem;
        box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2);

        &:hover,
        &:focus {
          background-color: $color-secondary !important;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }

  &__description {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1.4;
  }

  &__header {
    background-color: $color-primary;
    color: $color-light;
    font-weight: bold;
    h1 {
      margin: 0;
      padding: var(--arc-spacing-x-small) var(--arc-spacing-small);
    }
  }

  &__zoom-container {
    display: flex;
    justify-content: flex-end;
  }

  &__zoom-btn {
    font-weight: bold;
    margin-top: 1rem;
  }

  .layer {
    &__header {
      padding: 0 0 0 var(--arc-spacing-normal) !important;

      .mat-content {
        align-items: center;
        justify-content: space-between;
      }
      mat-checkbox {
        width: auto;
      }
    }

    &__label {
      font-weight: bold;
      line-height: var(--arc-line-height-dense);
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      mat-icon {
        font-size: 1rem;
        height: 1rem;
        color: $color-button;
      }
    }

    &__tooltip {
      font-size: var(--arc-font-size-xx-small);
    }

    .mat-expansion-panel {
      box-shadow: 0px 2px 3px rgba(118, 118, 118, 0.2);
    }

    .mat-expansion-panel-header {
      height: auto;
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0.5rem 0;
  }
}

.rewild-tooltip {
  font-size: var(--arc-font-size-xx-small);
  a {
    color: #ffd500;
  }
}

/* Overwrite the default scrollbar */
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-thumb {
  border: 0.2rem var(--arc-border-style) transparent;
}
